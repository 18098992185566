import { ClipType } from '@air/api/types';
import { setUploadImageLoadingAction, setUploadImagePreviewAction, Upload } from '@air/redux-uploader';
import { Box } from '@air/zephyr';
import { drawImage } from 'canvas-object-fit';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { isPreviewAvailable } from '~/components/Upload/utils/isPreviewAvailable';
import { UPLOAD_PREVIEW_SIZE } from '~/constants/upload';

interface UploadImageLoaderProps {
  upload: Upload;
}

export const UploadPreviewLoader = ({
  upload,
  upload: {
    apiUploadData: { type },
  },
}: UploadImageLoaderProps) => {
  const dispatch = useDispatch();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const loadVideoPreview = useCallback(() => {
    const video = document.createElement('video');
    video.preload = 'auto';
    const source = document.createElement('source');
    video.appendChild(source);
    const src = URL.createObjectURL(upload.file);
    source.src = src;
    source.type = `video/${upload.apiUploadData.ext}`;

    video.addEventListener('loadeddata', () => {
      if (canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        if (ctx) {
          const videoRatio = video.videoWidth / video.videoHeight;
          canvas.height = UPLOAD_PREVIEW_SIZE;
          canvas.width = UPLOAD_PREVIEW_SIZE * videoRatio;
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const url = canvas.toDataURL();
          dispatch(setUploadImagePreviewAction({ uploadId: upload.id, imageBase64: url }));
          URL.revokeObjectURL(src);
        }
      }
    });
    video.load();
  }, [dispatch, upload.apiUploadData.ext, upload.file, upload.id]);

  // this has better performance than using getBase64
  const loadImagePreview = useCallback(() => {
    const image = document.createElement('img');
    const src = URL.createObjectURL(upload.file);

    image.onload = function () {
      if (canvasRef.current) {
        const canvas = canvasRef.current;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          canvas.width = UPLOAD_PREVIEW_SIZE;
          canvas.height = UPLOAD_PREVIEW_SIZE;

          drawImage(ctx, image, 0, 0, UPLOAD_PREVIEW_SIZE, UPLOAD_PREVIEW_SIZE, { objectFit: 'cover' });
          dispatch(setUploadImagePreviewAction({ uploadId: upload.id, imageBase64: canvas.toDataURL() }));
          URL.revokeObjectURL(src);
        }
      }
    };
    image.src = src;
  }, [dispatch, upload.file, upload.id]);

  useEffect(() => {
    if (isPreviewAvailable(upload) && !upload.imageBase64 && !upload.isImageLoading) {
      dispatch(setUploadImageLoadingAction({ uploadId: upload.id, isImageLoading: true }));
      if (type === ClipType.video) {
        loadVideoPreview();
      } else {
        loadImagePreview();
      }
    }
  }, [dispatch, loadImagePreview, loadVideoPreview, type, upload, upload.imageBase64]);

  if (upload.imageBase64 || !isPreviewAvailable(upload)) {
    return null;
  }

  return (
    <Box tx={{ display: 'none', width: UPLOAD_PREVIEW_SIZE, height: UPLOAD_PREVIEW_SIZE, overflow: 'hidden' }}>
      <canvas ref={canvasRef} />
    </Box>
  );
};
